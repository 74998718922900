import {useCallback, useEffect} from "react";
import {debounce} from "lodash";
import {SLIDE_LAST_NUM} from "../components/main/Main";
import {useTranslation} from "react-i18next";

export function useSlide({
                           isDesktop,
                           setYMovingValue,
                           slideNum,
                           setSlideNum,
                           setIsShownHeaderBg,
                         }) {

  const {t} = useTranslation(['main']);

  const windowHeight = window.innerHeight;

  useEffect(() => {
    setYMovingValue((slideNum - 1) * (-windowHeight));

    if (slideNum === 1) {
      setIsShownHeaderBg(false);
    } else {
      setIsShownHeaderBg(true);
    }
  }, [slideNum]);

  const goUp = useCallback((currentSlide) => {
    if (1 < currentSlide) {
      setSlideNum(currentSlide - 1);
    }
  }, []);

  const goDown = useCallback((currentSlide) => {
    if (currentSlide < SLIDE_LAST_NUM) {
      setSlideNum(currentSlide + 1);
    }
  }, []);

  const keyDownListener = useCallback(e => {
    switch (e.key) {
      case "PageDown":
      case "ArrowDown":
        e.preventDefault();
        goDown(slideNum);
        break;
      case "PageUp":
      case "ArrowUp":
        e.preventDefault();
        goUp(slideNum);
        break;
    }
  }, [slideNum]);

  useEffect(() => {
    document.title = t('documentTitle');
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', keyDownListener);
    return () => {
      window.removeEventListener('keydown', keyDownListener);
    };
  }, [slideNum]);

  const setWheelListener = (ref, currentSlide) => {

    const wheelListener = e => {
      e.preventDefault();
      if (e.deltaY < 0) {
        goUp(currentSlide);
      } else {
        goDown(currentSlide);
      }
    };

    const wheelListenerTimer = debounce(wheelListener, 10);

    ref.current.addEventListener('wheel', wheelListenerTimer);
    return () => {
      ref.current.removeEventListener('wheel', wheelListenerTimer);
    };
  };

  const setScrollListener = (ref) => {

    const scrollListener = () => {
      if (ref.current.scrollTop > 0) {
        setIsShownHeaderBg(true);
      } else {
        setIsShownHeaderBg(false);
      }
    };

    const scrollListenerTimer = debounce(scrollListener, 10);
    ref.current.addEventListener('scroll', scrollListenerTimer);
    return () => {
      ref.current.removeEventListener('scroll', scrollListenerTimer);
    };
  };

  if (isDesktop) {
    return {
      setWheelListener,
      setScrollListener: () => {}
    };
  }

  return {
    setWheelListener: () => {},
    setScrollListener
  };
}