import style from "../assets/scss/Footer.module.scss";
import mainStyle from "../assets/scss/main/Main.module.scss";
import {FooterCompanyInfo} from "./FooterCompanyInfo";
import {FooterInquiry} from "./main/FooterInquiry";
import {SlideContext} from "../hooks/MainContentSlideContext";
import {useContext, useEffect, useRef} from "react";

export function Footer({sectionNum}) {
  const sectionRef = useRef();
  const {setWheelListener} = useContext(SlideContext);

  useEffect(() => {
    setWheelListener(sectionRef, sectionNum);
  }, []);

  return <footer className={`${mainStyle.slide} ${style.footer}`}
                 ref={sectionRef}>
    <div className={style.transparentBg}></div>
    <FooterInquiry sectionNum={sectionNum} />
    <FooterCompanyInfo/>
  </footer>;
}