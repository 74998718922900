import style from '../assets/scss/Footer.module.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function FooterCompanyInfo() {
  const { t, i18n } = useTranslation(['footer']);
  const [language, setLanguage] = useState(i18n.language);

  const setLang = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    document.cookie = `lang=${lang}`;
  };

  useEffect(() => {
    const cookies = document.cookie.split(';');
    const langCookie = cookies.find((it) => it.includes('lang'));
    let lang = 'ko';
    if (typeof langCookie === 'undefined') {
      lang = window.navigator.language;
      document.cookie = `lang=${lang}`;
    } else {
      lang = langCookie.split('=')[1];
    }

    lang = lang.substring(0, 2);
    switch (lang) {
      case 'ko':
        setLang('ko');
        break;
      case 'ko-KR':
        setLang('ko');
        break;
      case 'en':
        setLang('en');
        break;
      case 'ru':
        setLang('ru');
        break;
      default:
        setLang('en');
        break;
    }
  }, []);

  return (
    <div className={style.companyInfo}>
      <h2 className="blind">회사 정보</h2>
      <div className={style.content}>
        <div className={style.textArea}>
          <div className={style.text}>
            <span className={style.companyName}>{t('companyName')}</span>
            <br />
            <span className={style.otherInfo}>
              {t('ceoLabel')}&nbsp;&nbsp;|&nbsp;&nbsp;{t('ceoName')}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <br className={style.breakMobile} />
              {t('companyRegistrationNumberLabel')}
              &nbsp;&nbsp;|&nbsp;&nbsp;397-81-01770&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <br className={style.breakMobile} />
              {t('industryLabel')}&nbsp;&nbsp;|&nbsp;&nbsp;{'제2020-부산수영-0962호'}
              <br />
              {t('inquiryLabel')}&nbsp;&nbsp;|&nbsp;&nbsp;MAIL : contact@feel-good.io &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <br className={style.breakMobile} />
              {'전화'}&nbsp;&nbsp;|&nbsp;&nbsp;051-747-4050
              <br />
              {t('busanAddress')}
              <br />
              {t('gyeonggiAddress')}
            </span>
          </div>
          <div className={style.language}>
            <label htmlFor="languageSelector">Language</label>
            <select id="languageSelector" value={language} onChange={(e) => setLang(e.target.value)}>
              <option value="ko">한국어</option>
              <option value="ru">Русский</option>
              <option value="en">English</option>
            </select>
          </div>
        </div>
        <div className={style.image}></div>
      </div>
    </div>
  );
}
