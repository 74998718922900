import {useContext, useEffect, useState} from "react";
import {SlideContext} from "./MainContentSlideContext";

export function useMainSlide(ref, sectionNum) {
  const {slideNum, setWheelListener} = useContext(SlideContext);
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    if (slideNum === sectionNum) {
      setIsShown(true);
    } else {
      setIsShown(false);
    }
  }, [slideNum]);

  useEffect(() => {
    setWheelListener(ref, sectionNum);
  }, []);

  return {
    isShown
  };
}