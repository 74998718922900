import style from "../../assets/scss/main/AboutServiceKpass.module.scss";
import mainStyle from "../../assets/scss/main/Main.module.scss";
import kpassImg from "../../assets/images/main/about_kpass_pic.png";
import {SubPageLink} from "../sub/SubPageLink";
import {useContext, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useMainSlide} from "../../hooks/useMainSlide";
import {UserAgentContext} from "../../hooks/UserAgentContext";

export function AboutServiceKpass({sectionNum}) {
  const {t, i18n} = useTranslation(['mainKpass']);
  const sectionRef = useRef();
  const {isShown} = useMainSlide(sectionRef, sectionNum);
  const {isDesktop} = useContext(UserAgentContext);

  return <section className={`${mainStyle.slide} ${style.wrapper}`}
                  ref={sectionRef}>
    <article className={style.content}>
      <div className={style.bgImageRightTop}></div>
      <div className={style.bgImageLeftBottom}></div>
      <div className={style.text}>
        <h3 className={style.title}>{t('title')}</h3>
        <div className={`${style.phrase} ${isShown ? style.show : style.hide}`}>
          <h4 className={style.summary}>
            {
              isDesktop ?
                <>
                  {t('subTitle')}
                </>
                :
                <>
                  {t('subTitle1_mobile')}<br/>
                  {t('subTitle2_mobile')}
                </>
            }
          </h4>
          <p className={style.desc}>
            {t('desc1')}<br/>
            {t('desc2')}
          </p>
        </div>
        <SubPageLink link={"/about-kpass"}
                     className={`${style.detailLink} ${isShown ? style.show : style.hide} ${i18n.language}`}/>
      </div>
      <div className={`${style.picture} ${isShown ? style.show : style.hide}`}>
        <img src={kpassImg} alt="k-pass 관련 이미지"/>
      </div>
    </article>
  </section>;
}