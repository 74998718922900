import {useEffect, useRef, useState} from "react";
import style from "../../assets/scss/sub/PhotoSlide.module.scss";
import leftArrow from "../../assets/images/sub_team/slide_left_arrow.svg";
import rightArrow from "../../assets/images/sub_team/slide_right_arrow.svg";
import jsonData from "../../data/teamPhotoSlideData.json";
import {useTranslation} from "react-i18next";

export function PhotoSlide({isShown}) {
  const {i18n} = useTranslation();

  const importAll = (r) => {
    const images = {};
    r.keys().forEach((item) => {
      images[item.replace('./', '')] = r(item);
    });
    return images;
  }

  const [imgData, setImgData] = useState({});
  const [selectedImgSrcArr, setSelectedImgSrcArr] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedPath, setSelectedPath] = useState('');
  const [selectedImgSummary, setSelectedImgSummary] = useState('');
  const [selectedImgDesc, setSelectedImgDesc] = useState('');
  const [monthSliderX, setMonthSliderX] = useState(0);

  useEffect(() => {
    const images = importAll(require.context('../../assets/images/sub_team/photo_slide', true, /\.(png|jpe?g|svg)$/));
    const data = {};
    Object.keys(images).forEach(imgKey => {
      const pathArr = imgKey.split('/');
      const dir = pathArr[0];
      const fileName = pathArr[1];

      if (!data[dir]) {
        data[dir] = [];
      }
      data[dir].push({
        fileName,
        path: images[imgKey]
      });
    });

    setImgData(data);

    setMonthSliderX(
      monthButtonSliderWrapperRef.current.getBoundingClientRect().x
    );

    initState();
    calculateIsFirstAndLast();
  }, [monthSliderX]);

  const initState = () => {
    if (jsonData) {
      const firstKey = jsonData[0].key;
      setSelectedMonth(firstKey);
      initImage(firstKey);
    }
  };

  const monthButtonSliderWrapperRef = useRef(null);
  const monthButtonSliderRef = useRef(null);

  const [isFirstBtnSlide, setIsFirstBtnSlide] = useState(false);
  const [isLastBtnSlide, setIsLastBtnSlide] = useState(false);

  const calculateIsFirstAndLast = () => {
    const wrapper = monthButtonSliderWrapperRef.current;
    const slider = monthButtonSliderRef.current;
    if (wrapper && slider) {
      const wrapperRect = wrapper.getBoundingClientRect();
      const sliderRect = slider.getBoundingClientRect();
      if (wrapperRect.left <= sliderRect.left) {
        setIsFirstBtnSlide(true);
      } else {
        setIsFirstBtnSlide(false);
      }

      if (wrapperRect.right >= sliderRect.right) {
        setIsLastBtnSlide(true);
      } else {
        setIsLastBtnSlide(false);
      }
    }
  };

  const showNextButtons = () => {
    const sliderWrapper = monthButtonSliderWrapperRef.current;
    const slider = monthButtonSliderRef.current;
    const wrapperRect = sliderWrapper.getBoundingClientRect();
    const sliderRect = slider.getBoundingClientRect();

    const newX = sliderRect.x - (wrapperRect.width + wrapperRect.x);
    const newRight = sliderRect.right - wrapperRect.width;

    if (newRight < wrapperRect.right) {
      const num = Math.abs(sliderRect.x) + wrapperRect.x + (sliderRect.right - wrapperRect.right);
      slider.style.transform = `translateX(-${num}px)`;
    } else {
      slider.style.transform = `translateX(${newX}px)`;
    }
    calculateIsFirstAndLast();
  };

  const showPrevButtons = () => {
    const sliderWrapper = monthButtonSliderWrapperRef.current;
    const slider = monthButtonSliderRef.current;
    const wrapperRect = sliderWrapper.getBoundingClientRect();
    const sliderRect = slider.getBoundingClientRect();
    let newX = (sliderRect.x - wrapperRect.x) + wrapperRect.width;

    if (newX > 0) {
      newX = 0;
    }

    slider.style.transform = `translateX(${newX}px)`;

    calculateIsFirstAndLast();
  };

  const onClickMonth = (monthKey) => {
    setSelectedMonth(monthKey);
    initImage(monthKey);
  };

  const initImage = (monthKey) => {
    if (imgData) {
      const imgArr = imgData[monthKey];
      if (imgArr && imgArr.length > 0) {
        setSelectedImgSrcArr(imgArr);
        setSelectedFileName(imgArr[0].fileName);
        setSelectedPath(imgArr[0].path);
      }
    }
  }

  const onClickImgThumbnail = (imgObj) => {
    setSelectedFileName(imgObj.fileName);
    setSelectedPath(imgObj.path);
  };

  useEffect(() => {
    if (selectedMonth && selectedFileName) {
      const monthData = jsonData.find(data => data.key === selectedMonth);
      setSelectedImgSummary(monthData.monthSummary[i18n.language]);
      setSelectedImgDesc(monthData.monthDesc[i18n.language]);
    }
  }, [selectedMonth, selectedFileName]);

  return <div className={style.wrapper}>
    <div className={style.buttonArea}>
      <button className={`${isFirstBtnSlide ? style.hide : ''} ${style.btn}`}
              onClick={showPrevButtons}>
        <img src={leftArrow} alt="날짜 왼쪽으로 이동 아이콘" />
      </button>
      <div className={style.monthButtonsWrapper} ref={monthButtonSliderWrapperRef}>
        <div className={`${style.slider} ${jsonData.length < 6 ? style.alignCenter : ''}`}
             ref={monthButtonSliderRef}>
          {
            jsonData.map(obj =>
              <button key={obj.key}
                      className={`${style.button} ${selectedMonth === obj.key ? style.selected : ''}`}
                      onClick={() => onClickMonth(obj.key)}>
                {obj.title[i18n.language]}
              </button>)
          }
        </div>
      </div>
      <button className={`${isLastBtnSlide ? style.hide : ''} ${style.btn}`}
              onClick={showNextButtons}>
        <img src={rightArrow} alt="날짜 오른쪽으로 이동 아이콘" />
      </button>
    </div>
    <div className={`${style.selectedPhotoDesc} ${isShown ? style.show : style.hide}`}>
      <div className={style.title}>
        {selectedImgSummary}
      </div>
      <div className={style.desc}>
        {selectedImgDesc}
      </div>
    </div>
    <div className={`${style.imageArea} ${isShown ? style.show : style.hide}`}>
      <div className={style.imgDetail}>
        {
          selectedPath ?
            <img src={`${selectedPath}`}/>
            :
            <></>
        }
      </div>
      <div className={style.monthImages}>
        {
          selectedImgSrcArr.map(imgSrcObj =>
            <button className={`${style.imgButton} ${selectedFileName === imgSrcObj.fileName ? style.selected : ''}`}
                    key={`${selectedMonth}/${imgSrcObj.fileName}`}
                    onClick={() => onClickImgThumbnail(imgSrcObj)}>
              <div className={style.whiteBg}></div>
              <img src={require(`../../assets/images/sub_team/photo_slide/${selectedMonth}/${imgSrcObj.fileName}`)} />
            </button>)
        }
      </div>
    </div>
  </div>
}