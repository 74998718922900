import topIcon from "../assets/images/top_icon.svg";

export function TopButton({targetEleRef}) {
  return <button className="topIcon"
                 onClick={() => {
                   if (targetEleRef) {
                     targetEleRef.current.scrollTo(0, 0);
                   } else {
                    window.scrollTo(0, 0);
                   }
                 }}>
    <img src={topIcon} alt="위로 이동 버튼 아이콘" />
  </button>;
}