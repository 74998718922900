import {useCallback, useEffect} from "react";

export function useScrollSetting({isMain, isDesktop, setIsShownHeaderBg}) {
  const onScroll = useCallback(() => {
    if (window.scrollY > 68) {
      setIsShownHeaderBg(true)
    } else {
      setIsShownHeaderBg(false)
    }
  }, [setIsShownHeaderBg])

  useEffect(() => {
    if (!isDesktop && isMain) {
      window.addEventListener('scroll', onScroll)
    }
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [setIsShownHeaderBg, isMain, isDesktop, onScroll])
}