import style from "../../assets/scss/main/AboutFeelGoodHistory.module.scss";
import mainStyle from "../../assets/scss/main/Main.module.scss";
import leftPic from "../../assets/images/main/history_bg_item_01.png";
import {useContext, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useMainSlide} from "../../hooks/useMainSlide";
import {UserAgentContext} from "../../hooks/UserAgentContext";

export function AboutFeelGoodHistory({sectionNum}) {
  const {t, i18n} = useTranslation(['mainFeelgoodHistory']);
  const sectionRef = useRef();
  const {isShown} = useMainSlide(sectionRef, sectionNum);
  const {isDesktop} = useContext(UserAgentContext);

  return <section className={`${mainStyle.slide} ${style.historyWrapper} ${i18n.language === "ru" ? style.ru : ""}`}
                  ref={sectionRef}>
    <article className={style.history}>
      <div className={style.yellowBg}></div>
      <div className={`${style.picture} ${isShown ? style.show : style.hide}`}>
        <img src={leftPic} alt={t('leftImgAlt')}/>
      </div>
      <div className={style.phrase}>
        <div className={style.line}></div>
        <h4 className={style.title}>{t('title')}</h4>
        <ul className={`${style.historyList} ${isShown ? style.show : style.hide}`}>
          <li>
            <span className={style.date}>{t(`history.list1.label`)}</span>
            <span>|</span>
            <span>{t('history.list1.content')}</span>
          </li>
          <li>
            <span className={style.date}>{t(`history.list2.label`)}</span>
            <span>|</span>
            <span>{t('history.list2.content')}</span>
          </li>
          <li>
            <span className={style.date}>{t(`history.list3.label`)}</span>
            <span>|</span>
            <span>{t('history.list3.content')}</span>
          </li>
          <li>
            <span className={style.date}>{t(`history.list4.label`)}</span>
            <span>|</span>
            <span>{t('history.list4.content')}</span>
          </li>
          <li>
            <span className={style.date}>{t(`history.list5.label`)}</span>
            <span>|</span>
            <span>{t('history.list5.content')}</span>
          </li>
          <li>
            <span className={style.date}>{t(`history.list6.label`)}</span>
            <span>|</span>
            <span>{t('history.list6.content')}</span>
          </li>
          <li>
            <span className={style.date}>{t(`history.list7.label`)}</span>
            <span>|</span>
            <span>{t('history.list7.content')}</span>
          </li>
          <li>
            <span className={style.date}>{t(`history.list8.label`)}</span>
            <span>|</span>
            <span>{t('history.list8.content')}</span>
          </li>
          <li>
            <span className={style.date}>{t(`history.list9.label`)}</span>
            <span>|</span>
            <span>{t('history.list9.content')}</span>
          </li>
        </ul>
      </div>
    </article>
  </section>;
}