import style from "../../assets/scss/main/Main.module.scss";
import {useContext, useEffect, useRef, useState} from "react";
import {SlideContext} from "../../hooks/MainContentSlideContext";
import {SlideLinks} from "./SlideLinks";
import {MainContent} from "./MainContent";
import {AboutFeelGood} from "./AboutFeelGood";
import {AboutFeelGoodHistory} from "./AboutFeelGoodHistory";
import {AboutServiceKpass} from "./AboutServiceKpass";
import {AboutTeamCulture} from "./AboutTeamCulture";
import {Footer} from "../Footer";
import {useSlide} from "../../hooks/useSlide";
import {useOutletContext} from "react-router-dom";
import {UserAgentContext} from "../../hooks/UserAgentContext";
import {TopButton} from "../TopButton";
import {useScrollSetting} from "../../hooks/useScrollSetting";

export const SLIDE_LAST_NUM = 6;

export function Main() {
  const mainRef = useRef();
  const mainContainerRef = useRef();

  const [yMovingValue, setYMovingValue] = useState(0);
  const [slideNum, setSlideNum] = useState(1);

  const {isDesktop} = useContext(UserAgentContext);
  const {isShownHeaderBg, setIsShownHeaderBg} = useOutletContext();
  useScrollSetting({isMain: true, isDesktop, setIsShownHeaderBg});
  const {
    setWheelListener,
    setScrollListener
  } = useSlide({
    isDesktop,
    setYMovingValue,
    slideNum,
    setSlideNum,
    setIsShownHeaderBg
  });

  useEffect(() => {
    setScrollListener(mainRef);
  }, []);

  return <>
    <main id="main"
          className={style.slideContainer}
          ref={mainRef}>
      {
        isDesktop ?
          <></>
          :
          isShownHeaderBg ?
            <TopButton/>
            :
            <></>
      }
      <h2 className="blind">본문 내용</h2>
      <SlideContext.Provider value={
        {
          slideNum: slideNum,
          setSlideNumber: setSlideNum,
          setWheelListener
        }
      }>
        <SlideLinks/>
        <div id="main-container"
             ref={mainContainerRef}
             style={
               isDesktop ?
                 {
                   transition: `transform 0.9s ease`,
                   transform: `translateY(${yMovingValue}px)`
                 }
                 :
                 {}
             }>
          <MainContent sectionNum={1}/>
          <AboutFeelGood sectionNum={2}/>
          <AboutFeelGoodHistory sectionNum={3}/>
          <AboutServiceKpass sectionNum={4}/>
          {/*<AboutServiceSadare sectionNum={5}/>*/}
          <AboutTeamCulture sectionNum={5}/>
          <Footer sectionNum={6}/>
        </div>
      </SlideContext.Provider>
    </main>
  </>;
}