import style from "../../assets/scss/main/AboutTeamCulture.module.scss";
import mainStyle from "../../assets/scss/main/Main.module.scss";
import {SubPageLink} from "../sub/SubPageLink";
import {useContext, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useMainSlide} from "../../hooks/useMainSlide";
import {UserAgentContext} from "../../hooks/UserAgentContext";

export function AboutTeamCulture({sectionNum}) {
  const {t, i18n} = useTranslation(['mainTeam']);
  const sectionRef = useRef();
  const {isShown} = useMainSlide(sectionRef, sectionNum);

  const {isDesktop} = useContext(UserAgentContext);

  return <>
    <section className={`${mainStyle.slide} ${style.wrapper}  ${i18n.language === "ru" ? style.ru : ""}`}
             ref={sectionRef}>
      <article className={style.content}>
        <h3 className={style.title}>{t('title')}</h3>
        <p className={`${style.desc} ${isShown ? style.show : style.hide}`}>
          {t('desc1')}<br/>
          {t('desc2')}
        </p>
        <SubPageLink link="/about-team-culture"
                     className={`${style.detailLink} ${isShown ? style.show : style.hide} ${i18n.language}`}
                     arrowColor="white"/>
      </article>
    </section>
  </>;
}