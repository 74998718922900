import {FooterCompanyInfo} from "../FooterCompanyInfo";
import footerStyle from "../../assets/scss/Footer.module.scss";
import style from "../../assets/scss/sub/AboutServiceKpassSub.module.scss";
import kpassLogo from "../../assets/images/logo/kpass_logo.svg";
import pic01 from "../../assets/images/sub_kpass/pic_01.png";
import {TopButton} from "../TopButton";
import {SubBgHeader} from "./SubBgHeader";
import {useOutletContext} from "react-router-dom";
import {useContext, useEffect, useRef, useState} from "react";
import {showArea} from "../commonFunctions";
import {useTranslation} from "react-i18next";
import {UserAgentContext} from "../../hooks/UserAgentContext";
import {useScrollSetting} from "../../hooks/useScrollSetting";

export function AboutServiceKpassSub() {
  const {t, i18n} = useTranslation(['subKpass']);
  const {setIsShownHeaderBg} = useOutletContext();
  const {isDesktop} = useContext(UserAgentContext);
  useScrollSetting({isMain: false, isDesktop});

  const firstTextRef = useRef();
  const picRef = useRef();
  const noticeRef = useRef();
  const [isShownFirstArea, setIsShownFirstArea] = useState(false);
  const [isShownSecondArea, setIsShownSecondArea] = useState(false);
  const [isShownThirdArea, setIsShownThirdArea] = useState(false);

  const listener = () => {
    showArea(firstTextRef.current, setIsShownFirstArea);
    showArea(picRef.current, setIsShownSecondArea);
    showArea(noticeRef.current, setIsShownThirdArea);
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    document.title = t('documentTitle');

    setIsShownHeaderBg(true);
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return <>
    <main className={`${style.main}`}>
      <TopButton />
      <SubBgHeader title={t('title')} bgClassName="kpass" />
      <article className={style.article01}>
        <div className={style.bgItem01}></div>
        {
          isDesktop ?
            <div className={style.bgItem02}></div>
            :
            <></>
        }
        <div className={style.logo}>
          <img src={kpassLogo} alt={t('logoAlt')} />
        </div>
        <div className={`${style.texts} ${isShownFirstArea ? style.show : style.hide}`}
             ref={firstTextRef}>
          <p className={style.summary}>
            {
              isDesktop ?
                <>
                  {t('summary.text1')}<br />
                  {t('summary.text2')}
                </>
                :
                <>
                  {t('summary.text1_mobile')}
                </>
            }
          </p>
          <span className={style.desc}>
            {
              isDesktop ?
                <>
                  {t('desc.text1')}<br />
                  {t('desc.text2')}<br />
                  {t('desc.text3')}
                </>
                :
                <>
                  {t('desc.text1_mobile')}<br />
                  {t('desc.text2_mobile')}
                </>
            }
          </span>
        </div>
      </article>
      <article className={style.article02}>
        <div className={style.bgItem}></div>
        <div className={style.pictureArea}>
          <div className={style.bgItem}></div>
          <div className={`${style.picture} ${isShownSecondArea ? style.show : style.hide}`}
               ref={picRef}>
            <img src={pic01} alt={t('gameImageAlt')} />
          </div>
        </div>
        <div className={`${style.textArea} ${i18n.language === "ru" ? style.ru : ""}`}>
          <div className={`${style.titleArea} ${isShownSecondArea ? style.show : style.hide}`}>
            <div className={style.line}></div>
            <h3 className={style.title}>
              {t('article.title')}
            </h3>
          </div>
          <div className={`${style.desc} ${isShownSecondArea ? style.show : style.hide}`}>
            <span className={style.bold}>
              {
                isDesktop ?
                  <>
                    {t('article.desc1')}<br />
                    {t('article.desc2')}<br />
                    {t('article.desc3')}
                  </>
                  :
                  <>
                    {t('article.desc1_mobile')}<br/>
                    {t('article.desc2_mobile')}
                  </>
              }
            </span>
          </div>
        </div>
      </article>
      <div className={style.openNotice}>
        <div className={style.notice}>
          <h3 className="blind">2023년 상반기 출시 예정</h3>
          <div className={style.bgItem01}></div>
          <div className={style.bgItem02}></div>
          <span className={`${style.title} ${isShownThirdArea ? style.show : style.hide}`}
                ref={noticeRef}>{t('notice.date')}</span>
          <span className={`${style.desc} ${isShownThirdArea ? style.show : style.hide}`}>
            {
              isDesktop ?
                <>
                  {t('notice.desc1')}<br />
                  {t('notice.desc2')}
                </>
                :
                <>
                  {t('notice.desc1_mobile')}<br />
                  {t('notice.desc2_mobile')}<br />
                  {t('notice.desc3_mobile')}
                </>
            }
          </span>
        </div>
        <div className={style.linkArea}>
          <a className={style.link}
             href="https://kpass.feel-good.io/"
             target="_blank">
            {t('linkBtnText')}
            <span className={style.arrow}></span>
          </a>
        </div>
      </div>
    </main>
    <footer className={`${footerStyle.footer}`}>
      <FooterCompanyInfo/>
    </footer>
  </>;
}