import style from "../../assets/scss/sub/ImageModal.module.scss";
import closeButtonIcon from "../../assets/images/sub_team/close_button.svg";

export function ImageModal({
                             showModal,
                             closeModal,
                             imagePath
                           }) {

  return <div className={`${style.imageModal} ${showModal ? style.show : ''}`}>
    <div className={style.imageArea}>
      <button onClick={closeModal}
              className={style.closeButton}>
        <img src={closeButtonIcon} alt={"닫기 아이콘"}/>
      </button>
      <img src={imagePath} alt={"상세 이미지"}/>
    </div>
  </div>;
}