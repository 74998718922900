import style from "../../assets/scss/main/MainContent.module.scss";
import mainStyle from "../../assets/scss/main/Main.module.scss";
import letterFImg from "../../assets/images/main/main_bg_F.png";
import letterGImg from "../../assets/images/main/main_bg_G.png";
import logo from "../../assets/images/logo/feelgood_logo.svg";
import {useRef} from "react";
import {useTranslation} from "react-i18next";
import {useMainSlide} from "../../hooks/useMainSlide";

export function MainContent({sectionNum}) {
  const {t, i18n} = useTranslation(['mainContent']);
  const mainContentRef = useRef();
  const {isShown} = useMainSlide(mainContentRef, sectionNum);

  return <section className={`${mainStyle.slide} ${style.mainContentWrapper} ${style[i18n.language]}`}
                  ref={mainContentRef}>
    <div className={style.mainContent}>
      <div className={style.textArea}>
        <p className={`${style.text} ${isShown ? style.show : style.hide} ${style[i18n.language]}`}>
          <span className={style.textLine1}>{t('text1')}</span>
          <span className={style.textLine2}>{t('text2')}</span>
          <span>{t('text3')}</span>
        </p>
        <div className={`${style.logo} ${isShown ? style.show : style.hide}`}>
          <img src={logo} alt="필굿 로고"/>
        </div>
      </div>
      <div className={style.imageArea}>
        <div className={`${style.letterF} ${isShown ? style.show : style.hide}`}>
          <img src={letterFImg}/>
        </div>
        <div className={`${style.letterG} ${isShown ? style.show : style.hide}`}>
          <img src={letterGImg}/>
        </div>
      </div>
      <div className={style.yellowBg}>
      </div>
      <div className={style.blueBg}></div>
    </div>
  </section>;
}