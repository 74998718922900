import common from './common.json'
import footer from './footer.json'
import mainTeam from './mainTeam.json'
import mainSadare from './mainSadare.json'
import mainKpass from './mainKpass.json'
import mainFeelgoodHistory from './mainFeelgoodHistory.json'
import mainFeelgood from './mainFeelgood.json'
import mainContent from './mainContent.json'
import topMenu from './topMenu.json'
import subKpass from './subKpass.json'
import subTeam from './subTeam.json'
import main from './main.json'

export default{
  common,
  footer,
  mainTeam,
  mainSadare,
  mainKpass,
  mainFeelgoodHistory,
  mainFeelgood,
  mainContent,
  topMenu,
  subKpass,
  subTeam,
  main
}