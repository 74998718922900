import style from "../../assets/scss/sub/AboutServiceSadareSub.module.scss";
import sadareLogo from "../../assets/images/logo/sadare_logo.svg";
import pic01 from "../../assets/images/sub_sadare/pic_01.png";
import pic02 from "../../assets/images/sub_sadare/pic_02.png";
import settingIcon from "../../assets/images/sub_sadare/setting_icon.svg";
import footerStyle from "../../assets/scss/Footer.module.scss";
import {FooterCompanyInfo} from "../FooterCompanyInfo";
import {SubBgHeader} from "./SubBgHeader";
import {useOutletContext} from "react-router-dom";
import {useEffect} from "react";
import {TopButton} from "../TopButton";
import {useRef, useState} from "react";
import {debounce} from "lodash";
import {showArea} from "../commonFunctions";
import {useScrollSetting} from "../../hooks/useScrollSetting";
import {useContext} from "react";
import {UserAgentContext} from "../../hooks/UserAgentContext";

export function AboutServiceSadareSub() {
  const {setIsShownHeaderBg} = useOutletContext();
  const {isDesktop} = useContext(UserAgentContext);
  useScrollSetting({isMain: false, isDesktop});

  useEffect(() => {
    setIsShownHeaderBg(true);
    // listener();
  }, []);

  const [isShownFirstArea, setIsShownFirstArea] = useState(false);
  const [isShownSecondArea, setIsShownSecondArea] = useState(false);
  const pic1Ref = useRef();
  const pic2Ref = useRef();

  const listener = () => {
    showArea(pic1Ref.current, setIsShownFirstArea);
    showArea(pic2Ref.current, setIsShownSecondArea);
  };

  useEffect(() => {
    window.addEventListener('scroll', debounce(listener, 15));
    document.title = "(주)필굿 | sadare 사다리";

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return <>
    <TopButton />
    <main className={style.main}>
      <SubBgHeader title="sadare　사다리" bgClassName="sadare" />
      <div className={style.logo}>
        <img src={sadareLogo} alt="사다리 로고 이미지" />
        <div className={style.bgItem}></div>
      </div>
      <article className={style.article01}>
        <div className={`${style.pictureArea}`}
             ref={pic1Ref}>
          <div className={style.bgItem}></div>
          <img className={`${isShownFirstArea ? style.show : style.hide}`}
               src={pic01} alt="예시 이미지" />
        </div>
        <div className={style.textArea}>
          <div className={`${style.titleArea} ${isShownFirstArea ? style.show : style.hide}`}>
            <div className={style.line}></div>
            <h3 className={style.title}>contents 1.　서포트 (Support)</h3>
          </div>
          <div className={`${style.contentArea} ${isShownFirstArea ? style.show : style.hide}`}>
            <p className={style.summary}>
              K-PASS 검사 결과의 정밀한 분석과 교육적 처치에 대한<br/>
              해설을 제공하고, 궁금한 점을 자유롭게 질문하여<br/>
              상담 심리 전문가의 다양한 답변을 확인할 수 있습니다.
            </p>
            <p className={style.desc}>
              상담 심리 전문가를 통해 개인의 유형별 특성에 따른 양질의 정보를 제공하고,<br/>
              자녀에게 효율적인 학습 전략, 역량 강화 교육 방안,<br/>
              더 나아가 직무 적성을 파악할 수 있도록 올바른 자녀 교육을 서포트합니다.
            </p>
          </div>
          <p className={`${style.notice} ${isShownFirstArea ? style.show : style.hide}`}>
            <span className={style.bold}>*2022년 하반기,</span>
            <span className={style.normal}> 안드로이드 앱 서비스 출시 예정입니다.</span>
          </p>
        </div>
      </article>
      <article className={style.article02}>
        <div className={`${style.textArea} ${isShownSecondArea ? style.show : style.hide}`}>
          <div className={`${style.titleArea} ${isShownSecondArea ? style.show : style.hide}`}>
            <div className={style.line}></div>
            <h3 className={style.title}>
              contents 2.　에듀케이션 (Education)
            </h3>
          </div>
          <div className={`${style.contentArea} ${isShownSecondArea ? style.show : style.hide}`}>
            <p className={style.summary}>
              K-PASS 검사 분석 결과에 따른<br/>
              자녀의 능력과 특성을 반영한 매칭을 통해<br/>
              가장 최적화된 맞춤형 교육 콘텐츠를 추천해 드립니다.
            </p>
            <p className={style.desc}>
              지역과 비용의 제약으로 다양한 교육이 불가능했던 한계를 극복하고<br/>
              다양한 분야의 질 높은 교육을 자녀의 특성에 맞추어 제안하며,<br/>
              전문화된 교육 플랜을 통해 본인의 재능을 발굴하고 발전시킬 수 있도록 지원합니다.<br/>
              자녀의 성장과 성공을 위해 사다리가 함께 하겠습니다.
            </p>
          </div>
          <p className={`${style.notice} ${isShownSecondArea ? style.show : style.hide}`}>
            *앱 서비스 준비 중입니다.
          </p>
        </div>
        <div className={`${style.pictureArea}`} ref={pic2Ref}>
          <div className={style.bgItem}></div>
          <img className={`${isShownSecondArea ? style.show : style.hide}`} src={pic02} alt="예시 이미지" />
        </div>
      </article>
      <div className={style.linkArea}>
        <div className={style.link}>
          사다리 홈페이지 준비중 입니다
          <img src={settingIcon} alt="준비 중 아이콘 이미지" />
        </div>
      </div>
    </main>
    <footer className={`${footerStyle.footer}`}>
      <FooterCompanyInfo/>
    </footer>
  </>;
}