const height = window.innerHeight;
const halfHeight = window.innerHeight / 2;

export const isOnScreen = (rectTop, rectBottom) => {
  if (0 <= rectTop && rectTop <= height) return true;
  if (0 <= rectBottom && rectBottom <= height) return true;
  if (rectTop <= 0 && height <= rectBottom) return true;
  return false;
};

export const showArea = (refCurrent, setIsShown) => {
  if (refCurrent) {
    const rect = refCurrent.getBoundingClientRect();
    if (isOnScreen(rect.top, rect.bottom)) {
      setIsShown(true);
    } else {
      setIsShown(false);
    }
  }
};

export const isOnScreenCenter = (rectTop, rectBottom) => {
  if (rectTop <= halfHeight && halfHeight < rectBottom) return true;
  return false;
};

export const isOnScreenTopOrBottom = (rectTop, rectBottom) => {
  if (rectTop === 0) return true;
  if (rectBottom === height) return true;
  return false;
}