import style from "../../assets/scss/main/Main.module.scss";
import {useContext} from "react";
import {SlideContext} from "../../hooks/MainContentSlideContext";

export function SlideLinks() {
  const {slideNum, setSlideNumber} = useContext(SlideContext);
  const lastSlideNum = 6;
  const lightSlideNumbers = [2, 6];

  const getActiveClassName = (num) => {
    if (slideNum === num) return style.active;
    return '';
  };

  const getThemeClassName = () => {
    if (lightSlideNumbers.some(num => num === slideNum)) return style.lightBtn;
    return style.darkBtn;
  };

  const onClickAnchor = (e, num) => {
    e.preventDefault();
    setSlideNumber(num);
  };

  return <ul className={`
      ${slideNum === lastSlideNum ? style.hide : ''}
      ${style.slideLinks} 
      ${style.darkBtn} ${getThemeClassName()}`}>
    <li className={`${style.link} ${getActiveClassName(1)}`}>
      <a href="#" onClick={e => onClickAnchor(e, 1)}>
        <span className="blind">디지털 기술로 미래 교육 문화를 혁신하기 위한 도전</span>
      </a>
    </li>
    <li className={`${style.link} ${getActiveClassName(2)}`}>
      <a href="#" onClick={e => onClickAnchor(e, 2)}>
        <span className="blind">필굿 소개</span>
      </a>
    </li>
    <li className={`${style.link} ${getActiveClassName(3)}`}>
      <a href="#" onClick={e => onClickAnchor(e, 3)}>
        <span className="blind">히스토리</span>
      </a>
    </li>
    <li className={`${style.link} ${getActiveClassName(4)}`}>
      <a href="#" onClick={e => onClickAnchor(e, 4)}>
        <span className="blind">K-PASS | 케이-패스</span>
      </a>
    </li>
    <li className={`${style.link} ${getActiveClassName(5)}`}>
      <a href="#" onClick={e => onClickAnchor(e, 5)}>
        <span className="blind">sadare | 사다리</span>
      </a>
    </li>
    <li className={`${style.link} ${getActiveClassName(6)}`}>
      <a href="#" onClick={e => onClickAnchor(e, 6)}>
        <span className="blind">팀 문화</span>
      </a>
    </li>
  </ul>;
}