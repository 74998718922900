import {Outlet} from "react-router-dom";
import './assets/scss/App.scss';
import './assets/scss/common.scss';
import {TopMenu} from "./components/TopMenu";
import {useState} from "react";
import {UserAgentContext} from "./hooks/UserAgentContext";

const MOBILE_MAX_WIDTH = 905;

function App() {

  const [isShownHeaderBg, setIsShownHeaderBg] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const isDesktop = window.innerWidth > MOBILE_MAX_WIDTH;

  return (
    <UserAgentContext.Provider value={{isDesktop}}>
      <div className="App">
        <TopMenu isShownHeaderBg={isShownHeaderBg}/>
        <Outlet context={{isShownHeaderBg, setIsShownHeaderBg, lastScrollTop, setLastScrollTop}} />
      </div>
    </UserAgentContext.Provider>
  );
}

export default App;
