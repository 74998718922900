import style from "../../assets/scss/Footer.module.scss";
import emailIcon from "../../assets/images/main/email_icon.svg";
import clockIcon from "../../assets/images/main/clock_icon.svg";
import {useContext, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useMainSlide} from "../../hooks/useMainSlide";
import {UserAgentContext} from "../../hooks/UserAgentContext";

export function FooterInquiry({sectionNum}) {
  const {t, i18n} = useTranslation(['footer']);
  const sectionRef = useRef();
  const {isShown} = useMainSlide(sectionRef, sectionNum);
  const {isDesktop} = useContext(UserAgentContext);

  return <section className={`${style.inquiry} ${style[i18n.language]}`} ref={sectionRef}>
    <div className={style.contactUs}>
      <h2 className="blind">문의 및 연락처</h2>
      <span className={style.sign}>Contact Us</span>
      <span className={style.text}>{t('contactUsDesc')}</span>
    </div>
    <dl className={style.contactWays}>
      <div className={`${style.way} ${style.email} ${isShown ? style.show : style.hide}`}>
        <dt>
          <img src={emailIcon} alt="이메일 아이콘"/>
          <span>{t('emailLabel')}</span>
        </dt>
        |
        <dd>contact@feel-good.io</dd>
      </div>
      <div className={`${style.way} ${style.operatingTime} ${isShown ? style.show : style.hide}`}>
        <dt>
          <img src={clockIcon} alt="시계 아이콘"/>
          <span>{t('operationHourLabel')}</span>
        </dt>
        |
        <dd>
          {t('operationHour1')}
          {isDesktop ? <> </> : <br/>}
          {t('operationHour2')}
        </dd>
      </div>
    </dl>
  </section>;
}