import style from "../../assets/scss/sub/PhotoSlideMobile.module.scss";
import jsonData from "../../data/teamPhotoSlideData.json";
import {usePhotoData} from "../../hooks/usePhotoData";
import {useState} from "react";
import {ImageModal} from "./ImageModal";
import {useTranslation} from "react-i18next";

export function PhotoSlideMobile() {
  const {i18n} = useTranslation();
  const {imgData} = usePhotoData();
  const [showModal, setShowModal] = useState(false);
  const [imagePath, setImagePath] = useState('');

  const onClickImage = (imagePath) => {
    setImagePath(imagePath);
    setShowModal(true);
  };

  const closeImageModal = () => setShowModal(false);

  return <ul className={style.list}>
    <ImageModal showModal={showModal}
                imagePath={imagePath}
                closeModal={closeImageModal}/>
    {
      jsonData.map((monthInfo, monthInfoIdx) =>
        <li className={style.item} key={`monthInfo-${monthInfoIdx}`}>
          <div className={style.title}>{monthInfo.title[i18n.language]}</div>
          <p className={style.texts}>
            <span className={style.summary}>{monthInfo.monthSummary[i18n.language]}</span>
            <span className={style.desc}>
              {monthInfo.monthDesc[i18n.language]}
            </span>
          </p>
          <div className={style.images}>
            {
              imgData[monthInfo.key] ?
                imgData[monthInfo.key].map((image, imageIdx) =>
                  <div key={`${monthInfo.key}-${imageIdx}`}
                          className={style.imageItem}
                          onClick={() => onClickImage(image.path)}>
                    <img src={image.path} alt={"팀문화 사진"}/>
                  </div>)
                :
                <></>
            }
          </div>
        </li>)
    }
  </ul>;
}