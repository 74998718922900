import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./assets/scss/reset.scss";
import {Main} from "./components/main/Main";
import {AboutTeamCultureSub} from "./components/sub/AboutTeamCultureSub";
import {AboutServiceKpassSub} from "./components/sub/AboutServiceKpassSub";
import {AboutServiceSadareSub} from "./components/sub/AboutServiceSadareSub";
import './lang';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App/>}>
          <Route path="" element={<Main/>}></Route>
          <Route path="about-kpass" element={<AboutServiceKpassSub/>}></Route>
          <Route path="about-sadare" element={<AboutServiceSadareSub/>}></Route>
          <Route path="about-team-culture" element={<AboutTeamCultureSub/>}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
