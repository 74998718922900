import {useEffect, useState} from "react";

export function usePhotoData() {

  const importAll = (r) => {
    const images = {};
    r.keys().forEach((item) => {
      images[item.replace('./', '')] = r(item);
    });
    return images;
  };

  const [imgData, setImgData] = useState({});

  useEffect(() => {
    const images = importAll(require.context('/src/assets/images/sub_team/photo_slide', true, /\.(png|jpe?g|svg)$/));
    const data = {};
    Object.keys(images).forEach(imgKey => {
      const pathArr = imgKey.split('/');
      const dir = pathArr[0];
      const fileName = pathArr[1];

      if (!data[dir]) {
        data[dir] = [];
      }
      data[dir].push({
        fileName,
        path: images[imgKey]
      });
    });

    setImgData(data);
  }, []);

  return {
    imgData
  };
}