import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function SubPageLink({link, className, arrowColor}) {

  const {t} = useTranslation(['common']);

  return <Link to={link}
               className={`subPageLink ${className}`}>
    <div className={`linkBody`}>
      <span>{t('subLinkText')}</span>
      <div className={`right-arrow ${arrowColor ?? 'blue'}`}></div>
    </div>
  </Link>;
}