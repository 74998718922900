import style from "../../assets/scss/sub/AboutTeamCultureSub.module.scss";
import {PhotoSlide} from "./PhotoSlide";
import {SubBgHeader} from "./SubBgHeader";
import footerStyle from "../../assets/scss/Footer.module.scss";
import {FooterCompanyInfo} from "../FooterCompanyInfo";
import {TopButton} from "../TopButton";
import {useOutletContext} from "react-router-dom";
import {useEffect} from "react";
import {debounce} from "lodash";
import {useRef, useState} from "react";
import {showArea} from "../commonFunctions";
import {useContext} from "react";
import {UserAgentContext} from "../../hooks/UserAgentContext";
import {useScrollSetting} from "../../hooks/useScrollSetting";
import {PhotoSlideMobile} from "./PhotoSlideMobile";
import {useTranslation} from "react-i18next";

export function AboutTeamCultureSub() {
  const {t} = useTranslation(['subTeam']);

  const {setIsShownHeaderBg} = useOutletContext();
  const {isDesktop} = useContext(UserAgentContext);
  useScrollSetting({isMain: false, isDesktop});

  useEffect(() => {
    setIsShownHeaderBg(true);
    listener();
  }, []);

  const photoSlideRef = useRef();
  const [isShownSecondArea, setIsShownSecondArea] = useState(false);

  const listener = () => {
    showArea(photoSlideRef.current, setIsShownSecondArea);
  };

  useEffect(() => {
    window.addEventListener('scroll', debounce(listener, 15));
    document.title = t("documentTitle");

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return <>
    <main className={style.main}>
      <TopButton />
      <SubBgHeader title={t('title')} bgClassName={"team"} />
      <article className={`${style.article01}`}>
        <h3 className="blind">{t('summary')}</h3>
        <p className={style.summary}>
          {
            isDesktop ?
              <>{t('mainDescription')}</>
              :
              <span>
                {t('mainDescription_mobile1')}<br/>
                {t('mainDescription_mobile2')}
              </span>
          }
        </p>
        <p className={style.desc}>
          {
            isDesktop ?
              <>
                {t('subDescription1')}<br/>
                {t('subDescription2')}
              </>
              :
              <>
                <span>
                  {t('subDescription1_1_mobile')}<br/>
                  {t('subDescription1_2_mobile')}<br/>
                  {t('subDescription2_1_mobile')}<br/>
                  {t('subDescription2_2_mobile')}
                </span>
              </>
          }
        </p>
      </article>
      <article className={style.photoSlide} ref={photoSlideRef}>
        <h3 className="blind">{t('photoSlideTitle')}</h3>
        <div className={style.title}>{t('photoSlideTitleText')}</div>
        {
          isDesktop ?
            <PhotoSlide isShown={isShownSecondArea} />
            :
            <PhotoSlideMobile/>
        }
      </article>
    </main>
    <footer className={`${footerStyle.footer}`}>
      <FooterCompanyInfo/>
    </footer>
  </>;
}