import style from "../assets/scss/TopMenu.module.scss";
import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import headerLogo from "../assets/images/logo/feelgood_logo.svg";
import hamburgerBtn from "../assets/images/header/hamburger_bt.svg";
import closeBtn from "../assets/images/nav/close_bt.svg";
import {useTranslation} from "react-i18next";

export function TopMenu({isShownHeaderBg}) {
  const {t} = useTranslation(['topMenu']);
  const [showMenu, setShowMenu] = useState(false);
  const [pathName, setPathName] = useState('');

  const location = useLocation();

  useEffect(() => {
    setPathName(location.pathname);
  }, [location.pathname]);

  const onClickMenu = e => {
    setShowMenu(false);
    window.scrollTo(0, 0);
    e.target.children[0]?.click();
  };

  return <>
    <header className={`${style.headerWrapper} ${isShownHeaderBg ? style.show : ''}`}>
      <div className={style.content}>
        <h1 className="blind">(주)필굿</h1>
        <a className={`${style.logo} ${isShownHeaderBg ? style.show : ''}`} href="/">
          <img src={headerLogo} alt={t('logoAlt')}/>
        </a>
        <button className={style.button}
                onClick={() => setShowMenu(true)}>
          <img src={hamburgerBtn} alt={t('hamburgerBtnAlt')}/>
        </button>
      </div>
      <div className={`${style.bg} ${showMenu ? style.show : ''}`}></div>
      <div className={`${style.menu} ${showMenu ? style.show : ''}`}>
        <div className={style.closeBtnArea}>
          <button className={style.closeBtn}
                  onClick={() => setShowMenu(false)}>
            <img src={closeBtn} alt={t('drawerCloseBtnAlt')} />
          </button>
        </div>
        <nav className={style.nav}>
          <ul>
            <li className={pathName === '/' ? style.active : ''}
                onClick={onClickMenu}>
              <Link className={style.link} to="/">{t('navHome')}</Link>
            </li>
            <li className={pathName === '/about-kpass' ? style.active : ''}
                onClick={onClickMenu}>
              <Link className={style.link} to="/about-kpass">{t('navKPass')}</Link>
            </li>
            {/*<li onClick={onClickMenu}>*/}
            {/*  <Link className={style.link} to="/about-sadare">{t('navSadare')}</Link>*/}
            {/*</li>*/}
            <li className={pathName === '/about-team-culture' ? style.active : ''}
                onClick={onClickMenu}>
              <Link className={style.link} to="/about-team-culture">{t('navTeam')}</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  </>
    ;
}