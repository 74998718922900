import style from "../../assets/scss/main/AboutFeelGood.module.scss";
import mainStyle from "../../assets/scss/main/Main.module.scss";
import {useContext, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useMainSlide} from "../../hooks/useMainSlide";
import {UserAgentContext} from "../../hooks/UserAgentContext";

export function AboutFeelGood({sectionNum}) {
  const {t, i18n} = useTranslation(['mainFeelgood']);
  const sectionRef = useRef();

  const {isShown} = useMainSlide(sectionRef, sectionNum);
  const {isDesktop} = useContext(UserAgentContext);

  return <section className={`${mainStyle.slide} ${style.aboutFeelGoodWrapper}`}
                  ref={sectionRef}>
    <article className={style.introduction}>
      <div className={style.image}></div>
      <div className={style.phrase}>
        <h3 className={`${style.title} ${i18n.language === "ru" ? style.ru : ""}`}>{t('title')}</h3>
        <div className={`${style.texts} ${isShown ? style.show : style.hide}`}>
          <h4 className="blind">요약</h4>
          <p className={`${style.summary}`}>
            {t('subtitle')}
          </p>
          <p className={`${style.desc}`}>
            {
              isDesktop ?
                <>
                  {t('desc1')}<br/>
                  {t('desc2')}
                </>
                :
                <>
                  {t('desc1_mobile')}<br/>
                  {t('desc2_mobile')}
                </>
            }
          </p>
        </div>
      </div>
    </article>
  </section>;
}